<template>
<div id="app" class="app app-full-height app-without-header" style="background-color:#11192B ">
    <div class="login">
        <div class="login-content" style="border: 2px solid #fff ; padding:30px; border-radius: 8px;">
            <form class="was-validated" style="color:#fff !important; ">
            <h1 class="text-center">Sign In</h1>
            <div class="text-center mb-4">
            For your protection, please verify your yourself with Login.
            </div>
            <div class="mb-3">
            <label class="form-label">Phone Number</label>
            <input type="text" id="validationValidInput" v-model="forminput.email" class="form-control form-control-lg fs-15px" :class="{ 'is-invalid': forminput.email == '' && formerror.email == '', 'is-valid': forminput.email != '' && formerror.email != '' }"  placeholder="Enter your Email or Phone" />
            <div v-if="formerror.email" class=" btn-sm btn-danger"  >{{ formerror.email}}</div>
            <div v-if="msg.email" class=" btn-sm btn-danger text-center" >{{JSON.stringify(msg.email).replaceAll("[", "").replaceAll("]", "").replaceAll('"', "") }}</div>
            </div>
            <div class="mb-3">
            <div class="d-flex">
            <label class="form-label">Password</label>
            <router-link :to="{ name: 'resetpassword' }" class="ms-auto ">Forgot password?</router-link>
            </div>
            <input type="password" id="validationValidInput" v-model="forminput.password" class="form-control form-control-lg fs-15px" :class="{ 'is-invalid': formerror.password == '' && forminput.password == '', 'is-valid': formerror.password != '' && forminput.password != '' }"  placeholder="Enter your password" />
            <div v-if="formerror.password" class=" btn-sm btn-danger"  >{{ formerror.password }}</div>
            <div v-if="msg.password" class=" btn-sm btn-danger text-center"  >{{JSON.stringify(msg.password).replaceAll("[", "").replaceAll("]", "").replaceAll('"', "") }}</div>
            </div>
            
            <div class="mb-3">
            <div class="form-check">
            <input class="form-check-input" v-model="forminput.remember_me" type="checkbox"  id="customCheck1" />
            <label class="form-check-label fw-500" for="customCheck1">Remember Me Later</label>
            </div>
            <div v-if="msg.password == '' || msg.email == '' || msg != ''">
                <div  class=" btn-sm btn-danger text-center"  >{{JSON.stringify(msg).replaceAll("[", "").replaceAll("]", "").replaceAll('"', "") }}</div>
            </div>
            </div>
            <button v-if="loading == 'login'"  @click.prevent="submitformdata"  class="btn btn-outline-primary btn-block" style="width:100%" >
                <span  class="spinner-grow spinner-grow-sm" role="status"></span> Logging In
            </button>
            <button v-else class="btn btn-primary btn-lg d-block w-100 fw-500 mb-3" @click.prevent="submitformdata" >Sign In</button>

        
            </form>
        </div>
    </div>
</div>

<a href="#" data-click="scroll-top" class="btn-scroll-top fade"><i class="fa fa-arrow-up"></i></a>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    data(){
        return{
            forminput:{
                email:'',
                password:'',
                remember_me:''
        

           },
     formerror:{
                email:'',
                password:'',
                remember_me:''
            },

        }
    },


    computed:{
        ...mapGetters(['msg', 'loading'])
    },

    methods:{
        ...mapActions(['loginuser']),

        submitformdata(){
            
            if(this.forminput.email == ''){
                this.formerror.email = 'Email or Phone Number is Required';
                setTimeout(() => this.formerror.email = '', 3000)
            }else if(this.forminput.password == ''){
                this.formerror.password = 'Password is Required';
                setTimeout(() => this.formerror.password = '', 3000)
            }else{
            let data = {
                email:this.forminput.email,
                password:this.forminput.password,
                remember_me:this.forminput.remember_me
            }
            this.loginuser(data).then(()=>{
                this.forminput.email = '';
                this.forminput.password = '';
                
            })
            }
           
        },


    },

    created(){

    }
}
</script>